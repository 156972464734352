import gql from 'graphql-tag';

const prestamosTableQuery = gql`
    query prestamos($whereConditions:PrestamosWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        prestamos(where:$whereConditions,first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                cliente_id,
                empleado_id,
                tipo_incidencia_id,
                fecha_prestamo,
                descripcion,
                monto,
                saldo,
                descripcion,
                descuento,
                aplica_cobro,
                cliente {
                    id
                    nombre
                },
                empleado{
                    nombre,
                    apellido_paterno
                    nombre_completo
                },
                tipoIncidencia{
                    codigo
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const getPrestamo = gql `
    query prestamo($id: ID!) {
        prestamo(id: $id) {
            id,
            saldo,
            created_at,
        }
    }
`

const historicoPrestamosTableQuery = gql`

    query historico_prestamos($whereConditions: HistoricoPrestamosWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        historico_prestamos(where: $whereConditions,first: $numberItems, page: $numberPage, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                prestamo_id,
                fecha,
                monto,
                tipo,
                comentario,
                aplicado,
                prestamo{
                    monto,
                    saldo
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { prestamosTableQuery, historicoPrestamosTableQuery, getPrestamo };

export default queries;
