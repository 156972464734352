import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("prestamos/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("prestamos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.put("prestamos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("prestamos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("prestamos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateAbono(parametros) {
        return Vue.axios.put("prestamos/abono", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    deleteAbono(id) {
        return Vue.axios.delete("prestamos/abono/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    storeAbono(parametros) {
        return Vue.axios.post("prestamos/abono", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getEmpleados(parametros) {
        return Vue.axios.get("prestamos/empleados", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getTiposPrestamos(parametros) {
        return Vue.axios.get("prestamos/complementos", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}